<script setup lang="ts">
withDefaults(
  defineProps<{
    errorMessages?: string | string[];
    readonly?: boolean;
    disabled?: boolean;
    hideDetails?: boolean | string;
    label?: string;
    placeholder?: string;
    itemValue?: string;
    itemTitle?: string;
  }>(),
  {
    errorMessages: () => [],
    readonly: false,
    disabled: false,
    label: '',
    placeholder: '',
    itemValue: '',
    itemTitle: '',
    hideDetails: 'auto',
  }
);

const select = defineModel<string | number>();
</script>
<template>
  <v-select
    v-model="select"
    flat
    variant="solo-filled"
    :item-value="itemValue"
    :item-title="itemTitle"
    :hide-details="hideDetails"
    :error-messages="errorMessages"
    :placeholder="placeholder"
    :label="label"
    :readonly="readonly"
    :disabled="disabled"
  ></v-select>
</template>

<style></style>
